import React from 'react';
import { Link } from 'react-router-dom';
// import blogs from '../../api/blogs';

// import { Link } from 'react-router-dom'
import projMan from '../../images/projSub.jpg'
import blog4 from '../../images/blog-details/comments-author/img-2.jpg'
import blog5 from '../../images/blog-details/comments-author/img-3.jpg'
import blog6 from '../../images/blog-details/author.jpg'
import blogs from '../../api/blogs';
import { useParams } from 'react-router-dom';
import studentReq from '../../images/studentReq.jpg';


// const ClickHandler = () => {
//     window.scrollTo(10, 0);
// }

const StudentRec = (props) => {

    const { slug } = useParams()

    const BlogDetails = blogs.find(item => item.slug === slug)

    const submitHandler = (e) => {
        e.preventDefault()
    }

  return (
    <section className="blog-single-section section-padding">
            <div className="container">
                <div className="row">
                    <div className={`col col-lg-9 col-12 ${props.blRight}`}>
                        <div className="blog-content">
                            

                            <div className="tag-share">
                            <div className="post format-standard-image">
                                <div className="entry-media">
                                    <img src={studentReq} alt="" />
                                </div>
                                <ul className="entry-meta">
                                    <li><Link to="">FFSD GROUP</Link></li>
                                    <li><Link to="">28th January, 2024.</Link></li>
                                    <li><Link to="">Application Deadline: 4th February 2025.</Link></li>
                                </ul>
                                <h2>Job Title: International Student Recruitment Officer (Admissions Officer) <br /> Location: Ikeja, Lagos. Nigeria. <br/> Employment Type: Full-Time.</h2>
                                <h3>About Us</h3>
                                <p>FFSD Education is an international education recruitment company committed to guiding students through their journey to study abroad at top universities. Our mission is to provide exceptional support to applicants, ensuring a smooth admissions process from start to finish.</p>
                                <p>We are seeking a detail-oriented International Student Recruitment Officer (Admissions Officer) to manage application processing and offer-making for prospective students.</p>
                                
                                <h3>Key Responsibilities</h3>
                                <p>
                                    - Assist applicants through admissions, visa and pre-departure processes. <br />

                                    - Review and process student applications, ensuring all required documents are complete and accurate. <br />

                                    - Issue offers to applicants based on eligibility and admission criteria. <br />

                                    - Maintain accurate records of applications and correspondence with applicants. <br />

                                    - Communicate with students, parents, and educational partners to provide updates on application status. <br />

                                    - Collaborate with university partners to resolve application-related queries. <br />

                                    - Stay updated on admission policies and procedures for partner institutions. <br />
                                </p>

                                <h3>Qualifications and Skills</h3>
                                <p>
                                    - A bachelor’s degree is required. <br />

                                    - Experience in student recruitment, admissions processing or a similar administrative role is preferred. <br />

                                    - Strong attention to detail and organizational skills. <br />

                                    - Excellent communication skills, both written and verbal. <br />

                                    - Proficiency in Microsoft Office and data management systems. <br />

                                    - Ability to manage multiple applications and deadlines effectively. <br />

                                    - Knowledge of international education systems and visa processes (e.g., UK Tier 4, Canada Study Permit) is a plus. <br />
                                </p>

                                {/* <h3>What We Offer</h3>
                                <p>
                                    - Competitive salary.  
                                    - Opportunity to work on a high-profile project with an established company.  
                                    - Professional growth and development opportunities.
                                </p> */}

                                <h3>How to Apply</h3>
                                <p>
                                If you are passionate about helping students achieve their educational goals, we would love to hear from you. Please send your CV, degree certificate and a cover letter to <a href="mailto:careers@ffsdgroup.com"> careers@ffsdgroup.com </a> with the job title “International Student Recruitment Officer (Admissions Officer)” as the subject. 
                                </p>

                                <h3>Pay</h3>
                                <p>
                                150,000 to 200,000 per month 
                                </p>
                            </div>
                            {/* <h4>Location: Asaba, Delta State, Nigeria. <br/> Contract Duration: 6 Months to 1 Year.</h4> */}
                            </div>

                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
  )
}

export default StudentRec