import React, {Fragment} from 'react';
import { useParams, Link } from 'react-router-dom'
import blogs from '../../api/blogs.js'
import Navbar from '../../components/Navbar/Navbar.js';
import PageTitle from '../../components/pagetitle/PageTitle.js'
import BlogSingle from '../../components/BlogDetails/BlogSingle.js'
import Scrollbar from '../../components/scrollbar/scrollbar.js'
import Footer from '../../components/footer/Footer.js';
import Logo from '../../images/ffsdgroup.png'
import AllJobs from '../../components/EventSection/AllJobs.js';
import BlogSection from '../../components/BlogSection/BlogSection.js';
import CareerSection from '../../components/BlogSection/CareerSection.js';

const CareerDetails =() => {

    const { slug } = useParams()

    const BlogDetails = blogs.find(item => item.slug === slug)

    return(
        <Fragment>
            <Navbar hclass={'header-style-3'} Logo={Logo}/>
            <PageTitle pageTitle={'Job Roles'} pagesub={'Career'} />
            {/* <Link to="/career/Project-Management">hshs</Link> */}
            <CareerSection />
            {/* <AllJobs /> */}
            {/* <BlogSingle blLeft={'d-none'} blRight={'col-lg-10 offset-lg-1'}/> */}
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default CareerDetails;